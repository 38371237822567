import React, {
  useEffect,
  useState,
} from 'react';

import {
  OptionContainer,
  OptionsContainer,
  SelectedOptionContainer,
  TriangleIcon,
} from './style';

export type Option = {
  labelSelected: string
  labelSelectable: string
  value: string
};

export interface TinyDropdownProps {
  /**
   * 選択肢
   */
  options: Option[]

  /**
   * 初期値
   */
  defaultValue: string

  /**
   * 無効化するかどうか
   */
  disabled?: boolean

  /**
   * 変更時にハイライトするかどうか
   */
  highlightOnChange?: boolean

  /**
   * 違う選択肢が選ばれた時に呼び出されるハンドラー
   */
  onChangeHandler: (value: string) => void
}

export const TinyDropdown: React.FC<TinyDropdownProps> = ({
  options,
  defaultValue,
  disabled = false,
  highlightOnChange = false,
  onChangeHandler
}) => {

  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  useEffect(() => {
    setSelectedValue(defaultValue);
  }, [defaultValue]);

  useEffect(()=>{
    if (selectedValue !== defaultValue) {
      onChangeHandler(selectedValue);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);


  return (
    <div>
      <SelectedOptionContainer
        disabled={disabled}
        isbackgroundyellow={highlightOnChange === true && selectedValue !== defaultValue}
        onClick={
          () => {
            if (disabled === true) {
              // jsdom may not have pointer event feature.
              // Ref: https://github.com/jsdom/jsdom/issues/2527
              return;
            }
            setIsExpanded(!isExpanded);
          }
        }
        data-testid='selected-option'
      >
        <span>{options.find((option) => option.value === selectedValue)?.labelSelected || ''}</span>
        <TriangleIcon isexpanded={isExpanded? 'true' : 'false'} />
      </SelectedOptionContainer>
      <OptionsContainer
        visible={isExpanded}
      >
        {options.filter((option) => option.value !== selectedValue).map((option, idx) => (
          <OptionContainer
            key={idx}
            onClick={() => {
              if (disabled === true) {
                // jsdom may not have pointer event feature.
                // Ref: https://github.com/jsdom/jsdom/issues/2527
                return;
              }
              setIsExpanded(!isExpanded);
              setIsExpanded(false);
              setSelectedValue(option.value);
            }}
            data-testid='option'
          >{option.labelSelectable}</OptionContainer>
        ))}
      </OptionsContainer>
    </div>
  );
};
